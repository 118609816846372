export default {
	YA_COUNTER_NAME: '',	// fixme
	LIVE_HOST: "",	// empty for same host as FE
	API_HOST: "",	// empty for same host as FE
	API_DEFAULT_ACTION: '/api/Form',

	STORAGE_AUTH_TOKEN: "auth",

	ERROR_CODE_FORBIDDEN: "403",
	ERROR_CODE_NOT_FOUND: "404",
	API_EXCEPTION_EXISTS :'EXISTS',

	DEBOUNCE_INPUT_TIMEOUT: 800,

	//in _variables.scss, config.js and plugins/vuetify.js
	WIDTH_SM: 600,
	WIDTH_MD: 960,
	WIDTH_LG: 1264,
	WIDTH_XL: 1904,

	ROLE_SUPERADMIN: "SUPERADMIN",
	ROLE_CONTENT: "CONTENT",

	EMAIL: "info@proftake.ru",
}
