import Config from '@/config';

let state = {
    // данные из БЕ - переустанавливаются при каждом fetchPage
    response: {},	// объект ответа от БЕ в чистом виде
    session: {},	// данные текущей сессии
    common: {},			// общие для всех страниц
    page: {},			// индивидуальные для каждой страницы
    currentPage: {},	// текущая страница
    currentRouteName: null,

    // реактивные размеры экрана
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,

    withDrawer: false,

    navs: [
        {title: 'Сигналы', names: ['signals','signals-item','signals-new'], icon: 'mdi-chart-timeline-variant'},
        {title: 'Прогнозы', names: ['estimates', 'estimates-item', 'estimates-new'], icon: 'mdi-finance'},
        {title: 'Новости', names: ['news', 'news-item', 'news-new'], icon: 'mdi-newspaper-variant-outline'},
        {title: 'Отчеты', names: ['reports'], icon: 'mdi-chart-donut'},
        /*{title: 'Пользователи', names: ['users', 'users-item'], icon: 'mdi-account-multiple-outline'},
        {title: 'Платежи', names: ['payments', 'payments-item'], icon: 'mdi-credit-card-outline'},
        {title: 'Тарифы', names: ['tariffs', 'tariffs-item'], icon: 'mdi-cash'},*/
        {title: 'Настройки', href: "/bitrix", icon: 'mdi-cog-outline', roles: [Config.ROLE_SUPERADMIN]},
        //{title: 'Поддержка', names: ['support'], icon: 'mdi-help-circle-outline'},
        {title: 'Выход', names: ['logout'], icon: 'mdi-location-exit'},
    ],
};

export default state;
