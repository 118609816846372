export default [
    {
        name: 'auth',
        path: '/auth',
        component: () => import('../components/auth'),

    },
    {
        path: '/web', component: () => import('../components/layouts/layout-web'), meta: {},
        children: [
            {
                path: '/web/payment',
                name: 'payment',
                component: () => import('../components/web/payment'),
                //meta: {api: "AdminTransactionListController"}
            },
            {
                path: '/web/payment-frame/:orderId',
                name: 'payment-frame',
                props: true,
                component: () => import('../components/web/payment-frame'),
                meta: {api: "WebPaymentFrameController"}
            },
            {
                path: '/web/payment-status/:status',
                name: 'payment-status',
                props: true,
                component: () => import('../components/web/payment-status'),
            }
        ]
    },
    {
        path: '/', component: () => import('../components/layouts/layout-main'), meta: {},
        children: [
            {
                path: '/',
                name: 'signals',
                component: () => import('../components/signals'),
                meta: {api: "AdminSignalListController"}
            },
            {
                path: '/signals/new',
                name: 'signals-new',
                component: () => import('../components/signals-item'),
                meta: {api: "AdminSignalItemController"}
            },
            {
                path: '/signals/:id',
                name: 'signals-item',
                component: () => import('../components/signals-item'),
                props: true,
                meta: {api: "AdminSignalItemController"}
            },
            {
                path: '/estimates',
                name: 'estimates',
                component: () => import('../components/estimates'),
                meta: {api: "AdminEstimateListController"}
            },
            {
                path: '/estimates/new',
                name: 'estimates-new',
                component: () => import('../components/estimates-item'),
                meta: {api: "AdminEstimateItemController"}
            },
            {
                path: '/estimates/:id',
                name: 'estimates-item',
                component: () => import('../components/estimates-item'),
                props: true,
                meta: {api: "AdminEstimateItemController"}
            },
            {
                path: '/news',
                name: 'news',
                component: () => import('../components/news'),
                meta: {api: "AdminNewsListController"}
            },
            {
                path: '/news/new',
                name: 'news-new',
                component: () => import('../components/news-item'),
                //meta: {api: "AdminNewsItemController"}
            },
            {
                path: '/news/:id',
                name: 'news-item',
                component: () => import('../components/news-item'),
                props: true,
                meta: {api: "AdminNewsItemController"}
            },
            {
                path: '/reports',
                name: 'reports',
                component: () => import('../components/reports'),
                meta: {api: "AdminReportListController"}
            },
            /*{
                path: '/users',
                name: 'users',
                component: () => import('../components/users'),
                //meta: {api: "AdminUserListController"}
            },
            {
                path: '/payments',
                name: 'payments',
                component: () => import('../components/payments'),
                //meta: {api: "AdminTransactionListController"}
            },

            {
                path: '/tariffs',
                name: 'tariffs',
                component: () => import('../components/tariffs'),
                //meta: {api: "AdminReportListController"}
            },
            {
                path: '/settings',
                name: 'settings',
                component: () => import('../components/settings'),
                //meta: {api: "AdminRestrictedController"}
            },*/
            /*{
                path: '/orders/support',
                name: 'support',
                component: ()=>import('../components/support'),
                meta: {api: "AdminRestrictedController"}
            },*/
            {
                path: '/logout',
                name: 'logout',
                component: () => import('../components/logout'),
                meta: {api: "AdminRestrictedController"}
            },
        ]
    },
    {
        path: '/error/:code',
        name: 'error',
        component: () => import('../components/error'),
        props: true,
    },
    {
        path: '*',
        name: 'error-unknown',
        component: () => import('../components/error'),
        props: true,
    },
];
